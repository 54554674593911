import {Component, OnDestroy, OnInit} from '@angular/core';
import {RenderService} from "@src/app/services/render/render.service";
import Stats from "three/examples/jsm/libs/stats.module";
import {Subject} from "rxjs";
import {take, takeUntil} from "rxjs/operators";
import {ModalService} from "@src/app/services/modal/modal.service";
import {DebugService} from "@src/app/services/debug/debug.service";
import {InitializationService} from "@src/app/services/initialization/initialization.service";
import {InitDataService} from "@src/app/services/data/init-data.service";

@Component({
  selector: 'app-canvas',
  templateUrl: './canvas.component.html',
  styleUrls: ['./canvas.component.scss']
})
export class CanvasComponent implements OnInit, OnDestroy {

  private _stats: Stats = new Stats();
  public renderStatsEl: HTMLDivElement; // public for testing purposes
  private _canvasSelector: string = '#threeJsCanvas';
  private _componentDestroyed$: Subject<void> = new Subject();
  private _info: { memory: object, render: object } = {memory: {}, render: {}};
  private _animating: boolean;

  constructor(
    protected _renderService: RenderService,
    private _debugService: DebugService,
    private _modalService: ModalService,
    private _initService: InitializationService,
    private _dataService: InitDataService
  ) {
    this._initService.initialParametersSet.pipe(take(1)).subscribe(_ => {
      if (this._dataService.showMarkers) {
        this._renderService.correctLabelTop();
      }
    });
  }


  public ngOnInit(): void {
    if (this._checkWebGl()) {
      this._renderService.initializeRenderer(this._canvasSelector);
      this._renderService.initializeScene();

      this._debugService.debugActivated
        .pipe(takeUntil(this._componentDestroyed$))
        .subscribe(x => {
          if (x) {
            // add stats module to DOM
            this._stats.dom.classList.add('stats');
            document.body.appendChild(this._stats.dom);

            // add render monitor to DOM
            this.renderStatsEl = document.createElement('div');
            this.renderStatsEl.classList.add('gui-stats');
            document.body.appendChild(this.renderStatsEl);
            this._updateRenderInfoStats();

            // set up subscription for render info
            this._renderService.renderInfo
              .pipe(takeUntil(this._componentDestroyed$))
              .subscribe({
                next: value => {
                  this._info = value;
                  this._updateRenderInfoStats();
                }
              });
          } else {
            window.location.reload();
          }
        });

      this._renderService.animating
        .pipe(takeUntil(this._componentDestroyed$))
        .subscribe({
          next: value => {
            if (value) {
              this._animating = true;
              this._animate();
            } else {
              this._animating = false;
            }
          }
        });

      this._animate();
    }
  }

  public ngOnDestroy(): void {
    this._componentDestroyed$.next();
  }

  private _resizeCanvasToDisplaySize(): void {
    const canvas: HTMLCanvasElement = this._renderService.getDomElement();

    // look up the size of the canvas that is being displayed
    const width: number = canvas.clientWidth || 0;
    const height: number = canvas.clientHeight || 0;

    // adjust displayBuffer size to match
    // do this based on aspect ratio
    if (width / height !== this._renderService.getPerspectiveCameraAspectRatio()) {
      this._renderService.updateRenderSize(width, height);
    }
  }

  private _animate(): void {
    this._resizeCanvasToDisplaySize();
    this._renderService.setDisplaySpritePositions();
    this._renderService.renderScene();
    this._stats.update();

    if (this._animating) {
      requestAnimationFrame(this._animate.bind(this));
    }
  }

  private _updateRenderInfoStats(): void {
    let text = JSON.stringify(this._info, undefined, 2);
    text = text.substring(2, text.length - 2);
    this.renderStatsEl.innerText = text;
  }

  private _checkWebGl(): boolean {
    const canvas = document.createElement('canvas');
    if (!!window.WebGLRenderingContext &&
      (canvas.getContext('webgl') || canvas.getContext('experimental-webgl'))) {
      return true;
    } else {
      this._modalService.open('blocking-modal', {text: 'app.no_webgl'});
      return false;
    }
  }
}
