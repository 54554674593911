@if (woodTypes?.length > 1 && selectedWoodType) {
    <div>
        <div class="frame frame-alternate bottom-spacer" [class.selected]="selectedWoodType === WoodType.grenen"
             (click)="selectWoodType(woodTypes[0])" gaTag="woodtype.selection.grenen">
            <div class="frame-image-div">
                <app-catalog-item
                    [woodType]="woodTypes[0]"
                    [isWoodType]="true"
                    [itemSelected]="selectedWoodType === woodTypes[0]"
                    class="frame-wood-type-image"
                ></app-catalog-item>
                <div>
                    <p class="font-h3" [innerHTML]="'app.wood_type.standard_header' | translate"></p>
                    <ul class="font-body1 frame-list bottom-spacer"
                        [innerHTML]="'app.wood_type.standard_perks_list' | translate"></ul>
                    @if (grenenPrice) {
                        <p class="font-body1">{{ "app.wood_type.standard_price" | translate: {price: grenenPrice} }}</p>
                    }
                </div>
            </div>
        </div>
        <div class="frame frame-alternate" [class.selected]="selectedWoodType === WoodType.douglas"
             (click)="selectWoodType(woodTypes[1])" gaTag="woodtype.selection.douglas">
            <div class="frame-image-div">
                <app-catalog-item
                    [woodType]="woodTypes[1]"
                    [isWoodType]="true"
                    [itemSelected]="selectedWoodType === woodTypes[1]"
                    class="frame-wood-type-image"
                ></app-catalog-item>
                <div>
                    <p class="font-h3" [innerHTML]="'app.wood_type.upgrade_header' | translate"></p>
                    <ul class="font-body1 frame-list"
                        [innerHTML]="'app.wood_type.upgrade_perks_list' | translate"></ul>
                    @if (douglasPrice) {
                        <p class="font-body1">{{ "app.wood_type.upgrade_price" | translate: {price: douglasPrice} }}</p>
                    }
                </div>
            </div>
        </div>
    </div>
}
