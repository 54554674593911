import {Component, OnDestroy, OnInit} from '@angular/core';
import {Asset, Configuration, FatModuleVariant, Id, ModuleVariant, WoodType} from "@ess/jg-rule-executor";
import {InteractionService} from "@src/app/services/interaction/interaction.service";
import {RenderService} from "@src/app/services/render/render.service";
import {ModuleManagementService} from "@src/app/services/module-management/module-management.service";
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {FailedToRetrieveAssetError} from "@src/app/model/errors";
import {LoggingService} from "@src/app/services/logging/logging.service";
import {PriceCalculatorService} from "@src/app/services/price-calculator/price-calculator.service";
import {InitDataService} from "@src/app/services/data/init-data.service";
import {ButtonType} from "@src/app/library/components/buttons/button.type";
import {Icon, iconSource} from "@src/app/library/components/icon";
import {ModalService} from "@src/app/services/modal/modal.service";
import {FatFlatCatalogCategory} from "@src/app/model/fat-flat-catalog";
import {CategoryResultInfo} from "@src/app/model/category-result-info";
import {VariantWithCount} from "@src/app/model/variant_with_count";
import {VariantTranslationKeys} from "@src/app/model/variant-translation-keys";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-result-view',
  templateUrl: './result-view.component.html',
  styleUrls: ['./result-view.component.scss']
})
export class ResultViewComponent implements OnInit, OnDestroy {
  private _componentDestroyed$: Subject<void> = new Subject();

  public catalogCategories: FatFlatCatalogCategory[] = [];
  public categoriesDisplay: CategoryResultInfo[] = [];
  public allUsedBlueprintAssets: Set<Asset>;
  public woodType: WoodType;
  public buttonType: ButtonType = ButtonType.secondary;

  public priceToDisplay: string;
  public priceWithInstallation: string;
  public selectableWoodTypePrice: string;
  public multipleWoodTypes: boolean = false;
  public showInstallation: boolean = false;
  public installationEnabled: boolean = false;

  public hammerIconSrc: string = iconSource(Icon.hammer);
  public sawIconSrc: string = iconSource(Icon.saw);
  public drillIconSrc: string = iconSource(Icon.drill);
  public humanIconSrc: string = iconSource(Icon.human);
  public toolIconSrc: string = iconSource(Icon.tool);

  constructor(
    protected _renderService: RenderService,
    private _interactionService: InteractionService,
    private _moduleManagementService: ModuleManagementService,
    private _loggingService: LoggingService,
    private _priceCalculatorService: PriceCalculatorService,
    private _dataService: InitDataService,
    private _modalService: ModalService,
    private _translateService: TranslateService
  ) {
  }

  public ngOnInit(): void {
    this.catalogCategories = this._dataService.catalog.categories.map(v => new FatFlatCatalogCategory(this._moduleManagementService,v));
    this._updateVariantList(this._renderService.getCurrentConfiguration());

    this._interactionService.getConfigurationChanged$()
      .pipe(takeUntil(this._componentDestroyed$))
      .subscribe(newConfig => {
        this._updateVariantList(newConfig);
        this.installationEnabled = newConfig.installationService;
      });

    // Receive price updates and change the displayed prices accordingly
    this._priceCalculatorService.currentPrice$()
      .pipe(takeUntil(this._componentDestroyed$))
      .subscribe(newPrice => {
        this.showInstallation = this._priceCalculatorService.installationServiceAvailableForConfiguration();
        this.priceToDisplay = `${this._priceCalculatorService.priceFormatter(newPrice)}`;

        this.priceWithInstallation = `${this._priceCalculatorService.priceFormatter(
          newPrice + (!this.installationEnabled ? this._priceCalculatorService.getCurrentInstallationPrice() : 0)
        )}`;

        const availableWoodTypes = this._dataService.currentRetailerOptions?.woodTypesAvailable.filter(w => w.available === true);
        this.multipleWoodTypes = availableWoodTypes.length > 1;

        if (this.multipleWoodTypes) {
          const currentConfig = this._renderService.getCurrentConfiguration();
          const selectableWoodType = currentConfig.configurationPlacement.woodType === WoodType.grenen ? WoodType.douglas : WoodType.grenen;

          const price = this._priceCalculatorService.queryPriceForConfiguration(currentConfig, selectableWoodType);
          this.selectableWoodTypePrice = `${this._priceCalculatorService.priceFormatter(price)}`;
        }
      });
  }

  /**
   * Takes a configuration and updates the local variantsWithCount list containing: 1. which variants are present, 2. how many of them
   * there are 3. the icon belonging to that variant.
   */
  private _updateVariantList(config: Configuration): void {
    const variants: Id<ModuleVariant>[] = config.configurationPlacement.placements.map(
      plmnt => plmnt.variantId
    );
    const fatVariantsWithCount: Map<Id<ModuleVariant>, { fatVariant: FatModuleVariant, count: number }> =
      variants.reduce((acc, cur) => {
        if (acc.has(cur)) {
          // if we already have this variant, count++
          const updatedVal = acc.get(cur);
          updatedVal.count = updatedVal.count + 1;
          acc.set(cur, updatedVal);
        } else {
          // else, add variant to map
          const fatVariant = this._moduleManagementService.getFatModuleVariantById(cur);
          acc.set(cur, {fatVariant, count: 1});
        }
        return acc;
      }, new Map<Id<ModuleVariant>, { fatVariant: FatModuleVariant, count: number }>());


    this.categoriesDisplay = this.catalogCategories.map(category => new CategoryResultInfo(category.title, category.variants
      .filter(variant => fatVariantsWithCount.has(variant.variant.id))
      .map(variant => {
        const fatVarWithCount = fatVariantsWithCount.get(variant.variant.id);
        const key = `mvar.${variant.variant.groupId}.${VariantTranslationKeys.title}`;
        const title = this._translateService.instant(key);
        return new VariantWithCount(title, variant.variant.catalogIcon, fatVarWithCount.count);
      })));

    // Filter undefined also!
    this.allUsedBlueprintAssets = new Set(Array.from(fatVariantsWithCount.values()).map(f => f.fatVariant.blueprint.featureImage).filter(t => t));
    this.woodType = this._renderService.getCurrentConfiguration().configurationPlacement.woodType;
  }

  public handleImageError(assetId: Id<Asset>) {
    this._loggingService.error(new FailedToRetrieveAssetError(assetId));
  }
  public toggleInstallationService(): void {
    this.installationEnabled = !this.installationEnabled;
    this._priceCalculatorService.installationServiceChange(this.installationEnabled);
  }

  public openCarousel(index: number): void {
    this._modalService.open('carousel-modal', {list: Array.from(this.allUsedBlueprintAssets), index});
  }

  public openWoodType(): void {
    this._modalService.open('wood-type-modal', {hide: true})
  }

  /**
   * OnDestroy close subscriptions
   */
  public ngOnDestroy(): void {
    this._componentDestroyed$.next();
    this._componentDestroyed$.complete();
  }

  protected readonly Icon = Icon;
  protected readonly iconSource = iconSource;
}
