/* eslint-disable max-classes-per-file */
import {Catalog, CatalogCategory, Id, ModuleVariant, VersionedStatus} from "@ess/jg-rule-executor";
import {ModuleManagementService} from "@src/app/services/module-management/module-management.service";
import {ConfiguratorCatalog} from "@src/app/model/configurator-catalog";

export class FatFlatCatalog {
  id: Id<Catalog>;
  categories: FatFlatCatalogCategory[];

  constructor(
    moduleManagementService: ModuleManagementService,
    catalog: ConfiguratorCatalog
  ) {
    this.id = catalog.id;
    this.categories = catalog.categories?.map(category => new FatFlatCatalogCategory(moduleManagementService, category));
  }
}

export class FatFlatCatalogCategory {
  title: string;
  variants: CatalogVariant[];
  invalidVariants: CatalogVariant[];

  // constructor maps a proper CatalogCategory to the flat structure w/ full variants for use in the CatalogComponent
  constructor(
    moduleManagementService: ModuleManagementService,
    catalogCategory: CatalogCategory
  ) {
    this.title = catalogCategory.title;
    this.variants = catalogCategory.moduleCategories.reduce((acc, cur) => {
      const variants: CatalogVariant[] = cur.moduleVariantIds.map(id => {
        const fatModuleVariant = moduleManagementService.getFatModuleVariantById(id);
        return new CatalogVariant(fatModuleVariant.variant, fatModuleVariant.blueprint.status)
      });
      return [...acc, ...variants];
    }, new Array<CatalogVariant>());
  }

  cloneWithTitleOnly(): FatFlatCatalogCategory {
    return {
      title: this.title,
      variants: [],
      invalidVariants: []
    } as FatFlatCatalogCategory
  }
}

export class CatalogVariant {
  variant: ModuleVariant;
  status: VersionedStatus;

  constructor(variant: ModuleVariant, status: VersionedStatus) {
    this.variant = variant;
    this.status = status;
  }
}
