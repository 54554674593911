// eslint-disable-next-line max-classes-per-file
import {Catalog, CatalogCategory, Id, ModuleCategory, WoodType} from "@ess/jg-rule-executor";
import {
  GetCatalog_catalog,
  GetCatalog_catalog_woodTypeCatalogs_values_value_categories,
  GetCatalog_catalog_woodTypeCatalogs_values_value_categories_moduleCategories,
  GetCatalog_catalog_woodTypeCatalogs_values_value_filteredVariants_values_value,
  GetCatalog_catalog_woodTypeCatalogs_values_value_filterSettings_values_value,
  GetCatalog_catalog_woodTypeCatalogs_values_value_filterSettings_values_value_attributes,
  GetCatalog_catalog_woodTypeCatalogs_values_value_filterSettings_values_value_attributes_values_value,
  GetCatalog_catalog_woodTypeCatalogs_values_value_filterSettings_values_value_attributes_values_value_valueIcons,
} from "@src/app/generated/GetCatalog";

export class ConfiguratorCatalog {
  id: Id<Catalog>;
  categories: CatalogCategory[];
  filterSettings: Map<string, SettingValue>;
  filteredVariants: Map<string, FilteredVariant[]>;

  constructor(id?: Id<Catalog>, categories?: CatalogCategory[], filterSettings?: Map<string, SettingValue>, filteredVariants?: Map<string, FilteredVariant[]>) {
    this.filterSettings = filterSettings;
    this.filteredVariants = filteredVariants;
    this.categories = categories;
    this.id = id;
  }

  static fromGql(gql: GetCatalog_catalog): Map<WoodType, ConfiguratorCatalog> {
    const entries = gql?.woodTypeCatalogs.values.map((catalogGql) => {
      const filterSettingsMap = new Map<string, SettingValue>();
      catalogGql.value.filterSettings?.values.map(g => {
        filterSettingsMap.set(
          g.moduleCategoryReference,
          SettingValue.fromGql(g.value)
        );
      });
      const filteredVariantsMap = new Map<string, FilteredVariant[]>();
      catalogGql.value.filteredVariants?.values.map(g => {
        filteredVariantsMap.set(
          g.moduleCategoryReference,
          g.value.map(FilteredVariant.fromGql)
        );
      });
      return [
        catalogGql.key,
        new ConfiguratorCatalog(
          gql.id,
          catalogGql.value.categories?.map(PortalCatalogCategory.fromGql),
          filterSettingsMap,
          filteredVariantsMap
        )
      ] as [WoodType, ConfiguratorCatalog];
    });
    return new Map<WoodType, ConfiguratorCatalog>(entries);
  }

  public cloneForFatFlatCatalog(): ConfiguratorCatalog {
    return new ConfiguratorCatalog(
      this.id,
      this.categories.map(c => new CatalogCategory(
        c.title,
        c.moduleCategories.map(mc => new ModuleCategory(
          mc.id,
          mc.reference,
          mc.order,
          mc.customizable,
          mc.moduleVariantIds,
        ))
      ))
    )
  }
}

export class PortalCatalogCategory extends CatalogCategory {
  title: string;
  moduleCategories: ModuleCategory[];

  constructor(title: string, moduleCategories: ModuleCategory[]) {
    super(title, moduleCategories);
  }

  static fromGql(gql: GetCatalog_catalog_woodTypeCatalogs_values_value_categories): CatalogCategory {
    return new CatalogCategory(
      gql.title,
      gql.moduleCategories.map(PortalCatalogCategory.moduleCategoriesFromGql)
    );
  }

  static moduleCategoriesFromGql(gql: GetCatalog_catalog_woodTypeCatalogs_values_value_categories_moduleCategories): ModuleCategory {
    return new ModuleCategory(
      gql.id,
      gql.reference,
      gql.order,
      gql.customizable,
      gql.moduleVariantIds
    );
  }
}

export class SettingValue {
  attributes: SettingAttributes;

  constructor(attributes: SettingAttributes) {
    this.attributes = attributes;
  }

  static fromGql(gql: GetCatalog_catalog_woodTypeCatalogs_values_value_filterSettings_values_value): SettingValue {
    return new SettingValue(SettingAttributes.fromGql(gql.attributes));
  }
}

export class SettingAttributes {
  values: Map<string, SettingAttributeValue>;

  constructor(values: Map<string, SettingAttributeValue>) {
    this.values = values;
  }

  static fromGql(gql: GetCatalog_catalog_woodTypeCatalogs_values_value_filterSettings_values_value_attributes): SettingAttributes {
    const map = new Map<string, SettingAttributeValue>();
    gql.values.map(g => {
      map.set(
        g.attributeId,
        SettingAttributeValue.fromGql(g.value)
      );
    });
    return new SettingAttributes(
      map
    );
  }
}

export class SettingAttributeValue {
  valueIcons: ValueIcon;

  constructor(valuesIcons: ValueIcon) {
    this.valueIcons = valuesIcons;
  }

  static fromGql(gql: GetCatalog_catalog_woodTypeCatalogs_values_value_filterSettings_values_value_attributes_values_value): SettingAttributeValue {
    return new SettingAttributeValue(ValueIcon.fromGql(gql.valueIcons));
  }
}

export class ValueIcon {
  values: Map<string, IconValue>;

  constructor(values: Map<string, IconValue>) {
    this.values = values;
  }

  static fromGql(gql: GetCatalog_catalog_woodTypeCatalogs_values_value_filterSettings_values_value_attributes_values_value_valueIcons): ValueIcon {
    const map = new Map<string, IconValue>();
    gql.values.map(g => {
      map.set(g.attributeValue, g.iconAsset ? new IconValue(
        g.iconAsset.id,
        g.iconAsset.filename,
        g.iconAsset.url,
        g.iconAsset.mimeType
      ) : undefined);
    });
    return new ValueIcon(map);
  }
}

export class IconValue {
  id: any;
  mimeType?: string;
  filename: string;
  url: string;

  constructor(
    id: any,
    filename: string,
    url: string,
    mimeType?: string
  ) {
    this.id = id;
    this.filename = filename;
    this.url = url;
    this.mimeType = mimeType;
  }
}

export class FilteredVariant {
  attributeId: string;
  value: string;
  variantIds: string[];

  constructor(attributeId: any, value: string, variantIds: string[]) {
    this.attributeId = attributeId;
    this.value = value;
    this.variantIds = variantIds;
  }

  static fromGql(gql: GetCatalog_catalog_woodTypeCatalogs_values_value_filteredVariants_values_value): FilteredVariant {
    return new FilteredVariant(
      gql.attributeId,
      gql.value,
      gql.variantIds
    );
  }
}
