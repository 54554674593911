import {Injectable, OnDestroy} from '@angular/core';
import {
  Group,
  Material as ThreeMaterial,
  MaterialLoader,
  Mesh,
  MeshStandardMaterial,
  Object3D,
  Texture as ThreeTexture,
  TextureLoader
} from "three";
import {
  Configuration,
  FatModuleVariant,
  Id,
  Material,
  ModuleBlueprint,
  ModuleVariant,
  Texture
} from "@ess/jg-rule-executor";
import {GLTF, GLTFLoader} from "three/examples/jsm/loaders/GLTFLoader";
import {
  FailedToRetrieveBlueprintAssetError,
  MalformedBlueprintError,
  MalformedConfigError
} from "@src/app/model/errors";
import {zip} from "lodash";
import {ApplicationStateService} from "@src/app/services/applicationstate/application-state.service";
import {takeUntil} from "rxjs/operators";
import {Subject} from "rxjs";
import {InitDataService} from "@src/app/services/data/init-data.service";
import {NotificationService} from "@src/app/services/notification/notification.service";
import {Icon} from "@src/app/library/components/icon";
import {NotificationCardType} from "@src/app/components/notification-card/notification-card.type";
import {LoggingService} from "@src/app/services/logging/logging.service";

@Injectable({
  providedIn: 'root'
})
export class ModelLoadingService implements OnDestroy {
  // hier is al voorgesorteerd op een oplossing waar alle modellen @ startup ingeladen worden.
  public readonly modelCache: Map<Id<ModuleBlueprint>, Group> = new Map<Id<ModuleBlueprint>, Group>();
  private _serviceDestroyed$: Subject<void> = new Subject();
  private _loader: GLTFLoader = new GLTFLoader();
  private N_TRIES: number = 3;
  private _failed_to_load: Set<ModuleBlueprint> = new Set<ModuleBlueprint>();
  private _failed_to_load_latest: ModuleBlueprint;

  // Everything to do with materials and textures
  private _materialLoader: MaterialLoader = new MaterialLoader();
  private _textureLoader: TextureLoader = new TextureLoader();
  public readonly allMaterials: Map<string, ThreeMaterial> = new Map();
  public floorTexture: ThreeTexture;
  public skyTexture: ThreeTexture;
  public floorMaterial: MeshStandardMaterial;
  public skyMaterial: MeshStandardMaterial;
  public treeGlb1: Group;
  public treeGlb2: Group;
  public grass: Group;

  constructor(
    private _dataService: InitDataService,
    private _stateService: ApplicationStateService,
    private _loggingService: LoggingService,
    private _notificationService: NotificationService
  ) {
    _stateService.getState$()
      .pipe(takeUntil(this._serviceDestroyed$))
      .subscribe(s => {
        switch (s) {
          default: {
            // do nothing
            break;
          }
        }
      });
  }

  /**
   * Sets all textures and materials supplied in the material loader and the allMaterials array.
   */
  public setTexturesAndMaterials(input: { materials: Material[], textures: Texture[] }): void {
    // First load all textures and set them one by one in the given object
    let allTextures = {};
    input.textures.filter(t => t.asset).forEach(t => {
      const texture = this._textureLoader.load(t.asset.url, () => {
      }, () => {
      }, () => this._loggingService.warn(`Loading texture failed for id ${t.id}`));
      allTextures = {...allTextures, [t.name]: texture};
    });
    // Now set the textures in the material loader
    this._materialLoader.setTextures(allTextures);
    // Now load the materials
    input.materials.forEach(m => {
      // We need to format this a little, add MeshStandardMaterial and throw away anything that is null / undefined
      const x = {...(m as object), type: "MeshStandardMaterial"};
      Object.keys(x).forEach(key => {
        if (x[key] === null || x[key] === undefined) {
          delete x[key];
        }
      });
      // Set material and log if needed (wrapped in a try as the parse can give an error which is unrecoverable)
      try {
        this.allMaterials.set(m.name, this._materialLoader.parse(x));
      } catch (e) {
        this._loggingService.warn(`Loading material failed for id ${m.id}`);
      }
    });
  }

  /**
   * Sets the textures for the result scene
   */
  public setResultSceneTextures(): void {
    this.floorMaterial = this.allMaterials.get(this._dataService.currentRetailerOptions?.resultSceneFloorMaterialReference) as MeshStandardMaterial;
    this.skyMaterial = this.allMaterials.get(this._dataService.currentRetailerOptions?.resultSceneSkyMaterialReference) as MeshStandardMaterial;
    if (this.floorMaterial) {
      this.floorTexture = this.floorMaterial.map;
    }
    if (this.skyMaterial) {
      this.skyTexture = this.skyMaterial.map;
    }
    // leaving this commented out, since these will be re-added at a later date but do not currently exist and/or are not used
    // this._loader.load(
    //   "/assets/glbs/grass.glb",
    //   (g) => this.grass = g.scene,
    //   () => {
    //   },
    //   () => this._loggingService.warn(`Loading grass glb failed`)
    // );
    // this._loader.load(
    //   'assets/glbs/tree.glb',
    //   (g) => this.treeGlb1 = g.scene,
    //   () => {
    //   },
    //   () => this._loggingService.warn(`Loading tree glb 1 failed`)
    // );
    // this._loader.load(
    //   'assets/glbs/birch_tree.glb',
    //   (g) => this.treeGlb2 = g.scene,
    //   () => {
    //   },
    //   () => this._loggingService.warn(`Loading tree glb 2 failed`)
    // );
  }

  /**
   * Retrieves a list of FatVariants and finds the variants for the module placements in the configuration which aren't in the
   * given list of FatVariants (also looks in the undo-stack).
   */
  public static determineMissingVariantIds(config: Configuration, curFatVars: FatModuleVariant[]): Id<ModuleVariant>[] {
    return ModelLoadingService._allConfigVariantIds(config)
      .filter(variantId => !curFatVars.find(cfv => variantId === cfv.variant.id));
  }

  /**
   * Determine all the FatModuleVariants used in the given config.
   * throws if the configured variant is not found in allFatVars.
   *
   * @param config The configuration to get the fatVariants from
   * @param allFatVars List of FatVariant to obtain configured variants from
   */
  public getConfigFatVariantList(config: Configuration, allFatVars: FatModuleVariant[]): FatModuleVariant[] {
    const vars = ModelLoadingService._allConfigVariantIds(config).map(variantId =>
      allFatVars
        .filter((v, i, s) => s.indexOf(v) === i)
        .find(fv => fv.variant.id === variantId)
    );
    const undef = vars.filter(fv => fv === undefined);
    if (undef.length > 0) {
      this._loggingService.error(new MalformedConfigError(config.id));
      return undefined;
    }
    return vars;
  }

  /**
   * Free up memory by calling dispose() on all relevant materials and geometries of an unused Object3D
   */
  public static disposeNodeAttributes(node: Object3D): void {
    if (node instanceof Mesh) {
      // remove the geometry, if present
      if (node.geometry) {
        node.geometry.dispose();
      }

      // if a material is present, check for maps and dispose() those, and then dispose() the material itself
      const material: any = node.material;
      if (material) {
        if (material.map) {
          material.map.dispose();
        }
        if (material.lightMap) {
          material.lightMap.dispose();
        }
        if (material.bumpMap) {
          material.bumpMap.dispose();
        }
        if (material.normalMap) {
          material.normalMap.dispose();
        }
        if (material.specularMap) {
          material.specularMap.dispose();
        }
        if (material.envMap) {
          material.envMap.dispose();
        }
        material.dispose();
      }
    }
  }

  /**
   * digs through the tree of an object, and removes all cross-references between objects
   * use this if you want to ready objects for garbage collection
   *
   * Sadly this is a while loop but using forEach on diminishing arrays has issues in JS :(
   */
  public static clearObjectTree(node: Object3D): void {
    while (node.children.length > 0) {
      ModelLoadingService.clearObjectTree(node.children[0].removeFromParent());
    }
  }

  /**
   * Function to retrieve 3D models for a list of blueprints, and store them in the cache.
   * If a blueprint already is present in the cache, ignore it and do nothing.
   *
   * @param blueprints Array of ModuleBlueprints for which to fetch the models.
   * @param progress Progress function
   * @return resMap a map of blueprintId to Group, containing the requested 3D models
   */
  public async fetchModels$(blueprints: Set<ModuleBlueprint>, progress: (() => void)): Promise<Map<Id<ModuleBlueprint>, Group>> {
    const bpArray = Array.from(blueprints.values());
    const resMap = new Map<Id<ModuleBlueprint>, Group>();
    const models: Group[] = [];
    // make sure to fetch sequenced to prevent resource overload
    for (const bp of bpArray) {
      const res = await this.getModelForBlueprint$(bp);
      progress();
      models.push(res);
    }
    zip(bpArray, models).map(([bp, m]) => resMap.set(bp.id, m));
    return resMap;
  }

  /**
   * Get blueprint from cache if present, else call the loader to load it into memory.
   *
   * @param blueprint
   * @return Group object that has the visual data for the given blueprint
   */
  public async getModelForBlueprint$(blueprint: ModuleBlueprint): Promise<Group> {
    if (!this.modelCache.has(blueprint.id)) {
      const model: Group = await this._loadAssetForBlueprint$(blueprint);
      this.modelCache.set(blueprint.id, model);
    }
    return this.modelCache.get(blueprint.id);
  }

  /**
   * Informs the user of any missing blueprints found while running fetchModels$ or getModelForBlueprint$
   */
  public notifyFailedBlueprintsIfAny(onlyLatest: boolean = false): void {
    if (this._failed_to_load.size > 0) {
      let moduleText: string;
      if (onlyLatest) {
        moduleText = this._failed_to_load_latest.name;
      } else {
        moduleText = '\n- ' + Array.from(this._failed_to_load.values()).map(bp => bp.name).join('\n- ');
      }
      this._notificationService.showNotification(
        'app.notifications.load.failed_to_retrieve_asset',
        Icon.warn,
        NotificationCardType.warning,
        {modules: moduleText}
      );
    }
  }

  /**
   * Helper function to completely clear the cache on closing the application.
   */
  public clearCache(): void {
    const models: Group[] = Array.from(this.modelCache.keys()).map(bpId => this.modelCache.get(bpId));
    models.forEach(m => m.traverse(ModelLoadingService.disposeNodeAttributes)); // dispose textures and geometries
    models.forEach(ModelLoadingService.clearObjectTree); // clear object references
    this.modelCache.clear();
  }

  /**
   * OnDestroy close subscriptions and clear cache
   */
  ngOnDestroy(): void {
    this._serviceDestroyed$.next();
    this._serviceDestroyed$.complete();
    this.clearCache();
  }

  /**
   * Find all variants used in a given config and its history and return a list of their Id's.
   *
   * @private
   */
  private static _allConfigVariantIds(config: Configuration): Id<ModuleVariant>[] {
    return [
      ...config.configurationPlacement.placements.map(plcmt => plcmt.variantId),
      ...(config.history.flatMap(ancientConfig =>
        ancientConfig.configurationPlacement.placements.map(plcmt => plcmt.variantId)
      ))
    ];
  }

  /**
   * Function that tries to async load the asset from the blueprint's asset URL.
   *
   * @param blueprint
   * @private
   */
  private async _loadAssetForBlueprint$(blueprint: ModuleBlueprint): Promise<Group> {
    this._failed_to_load_latest = undefined;
    let retries = 0;
    let completedSuccessfully = false;
    let model: GLTF;
    let error;

    if (blueprint?.model?.url) {
      while (!completedSuccessfully && retries < this.N_TRIES) {
        retries++;
        await this._loader.loadAsync(blueprint.model.url).then(
          success => {
            model = success;
            completedSuccessfully = true;
            // Traverse the object to make it cast and receive shadows
            model.scene.traverse(function(node) {
              // @ts-ignore If the material has an encoding, it needs to be set al 3000
              if (node.material?.map?.encoding) {
                // @ts-ignore
                node.material.map.encoding = 3000;
              }
              node.castShadow = true;
              node.receiveShadow = true;
            });
          },
          err => {
            // if we encountered an error, wait a bit before retrying this operation
            // note that we only return the final error if we do not fix it in N retries
            error = err;
            if (retries < this.N_TRIES) {
              setTimeout(() => {
              }, 3000);
            }
          }
        );
      }

      if (completedSuccessfully) {
        if (this._failed_to_load.has(blueprint)) {
          this._failed_to_load.delete(blueprint);
        }
        return model.scene;
      } else {
        this._loggingService.error(new FailedToRetrieveBlueprintAssetError(blueprint.id, error));
        this._failed_to_load.add(blueprint);
        this._failed_to_load_latest = blueprint;
        return new Group();
      }
    } else {
      this._loggingService.error(new MalformedBlueprintError(blueprint.id));
      this._failed_to_load.add(blueprint);
      this._failed_to_load_latest = blueprint;
      return new Group();
    }
  }
}
