import {Component, EventEmitter, HostBinding, HostListener, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Observable, Subject} from "rxjs";
import {InteractionService} from "@src/app/services/interaction/interaction.service";
import {filter, map, take, takeUntil} from "rxjs/operators";
import {ApplicationStateService} from "@src/app/services/applicationstate/application-state.service";
import {ApplicationState} from "@src/app/model/applicationstate";
import {ViewMode} from "@src/app/model/view-mode";
import {InitDataService} from "@src/app/services/data/init-data.service";
import {Icon} from '@src/app/library/components/icon';
import {MenuComponent} from '@src/app/components/menu/menu.component';
import {ModalService} from '@src/app/services/modal/modal.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {
  private _componentDestroyed$: Subject<void> = new Subject();

  public menuSelectionIcons = [
    {icon: Icon.dots, disabled: false}
  ];

  public viewModeEnum = ViewMode;

  // Settings for spinner
  public initialTimeToleranceMs = 1000; // One second should be ok
  public configAssetsLoaded: boolean = false;
  public translationsAreLoaded: boolean = false;
  public appInitialized$: Observable<ApplicationState> = this._applicationStateService.getState$().pipe(
    filter(t => t === ApplicationState.appInitialized), take(1), takeUntil(this._componentDestroyed$)
  );

  // Settings for general spinner.
  // Spinner can be activated by calling activateGeneralSpinner on the ApplicationStateService
  public generalSpinnerFinished$: EventEmitter<void> = this._applicationStateService.generalSpinnerFinished$;
  public generalSpinnerText: string = this._applicationStateService.generalSpinnerText;
  public showGeneralSpinner$: Observable<boolean> = this._applicationStateService.showGeneralSpinner$.asObservable();
  public generalSpinnerToleranceMs = 300; // Since this is in response to user input and not a startup process, we should be a bit more aggressive

  public viewMode: ViewMode;

  public processingState: Observable<string> = this._applicationStateService.getNextState$().pipe(
    filter(t => t > ApplicationState.translationsAreLoaded), // Note: up to that point, no translations are present. So why bother?
    map(t => `app.spinner.state.${ApplicationState[t]}`));
  public currentProgress$: Observable<{ cur: number, expect: number }> = this._applicationStateService.getProgress$();

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) { // Don't need the event, but it needs to be passed
    // Define vh and vw according to 1% of the height and width
    // resize with menu open leads to strange layouts => close menu
    this._interactionService.closeMenu();
    // note: on Safari on iOS, the outer values might be inverted
    const innerHeight = window.innerHeight;
    const innerWidth = window.innerWidth;
    const outerMin = Math.min(window.outerHeight, window.outerWidth);
    const outerMax = Math.max(window.outerHeight, window.outerWidth);
    const portrait = (innerHeight > innerWidth);

    const outerHeight = portrait ? outerMax : outerMin;
    const outerWidth = portrait ? outerMin : outerMax;

    this.vh = `${Math.min(innerHeight, outerHeight) * 0.01}px`;
    this.vw = `${Math.min(innerWidth, outerWidth) * 0.01}px`;
  }

  @HostBinding("style.--vh") vh: string = '';
  @HostBinding("style.--vw") vw: string = '';

  @ViewChild("menuComponent", {static: false}) private _menuComponent: MenuComponent;

  constructor(
    private _interactionService: InteractionService,
    private _modalService: ModalService,
    private _applicationStateService: ApplicationStateService
  ) {
    // Set screen size on startup
    this.getScreenSize();
    _interactionService.getViewMode$()
      .pipe(takeUntil(this._componentDestroyed$))
      .subscribe(m => {
        this.viewMode = m
      });
  }

  /**
   * Handles clicks on menu or question buttons
   * For menu, simply sets to true.
   *
   * @param icon either 'dots' for menu or 'questionmark' for question
   */
  public menuOrQuestionClick(icon: Icon): void {
    switch (icon) {
      case Icon.dots: {
        this._toggleMenu();
        break;
      }
      case Icon.questionmark: {
        this._openHelpModal();
        break;
      }
    }
  }

  /**
   * Toggles visibility of the menu
   */
  private _toggleMenu(): void {
    this._menuComponent.toggleVisible();
  }

  /**
   * Function that handles the help click
   */
  private _openHelpModal(): void {
    this._menuComponent.closeMenu();
    this._modalService.open('help-modal');
  }

  ngOnInit(): void {
    this._applicationStateService.getState$().pipe(
      filter(t => t === ApplicationState.configAssetsLoaded), take(1) // If config assets are loaded, we are done for now
    ).subscribe(_ => {
      this.configAssetsLoaded = true
    });
    this._applicationStateService.getState$().pipe(
      filter(t => t === ApplicationState.translationsAreLoaded), take(1) // If config assets are loaded, we are done for now
    ).subscribe(_ => {
      this.translationsAreLoaded = true
    });
  }

  /**
   * OnDestroy close subscriptions
   */
  ngOnDestroy(): void {
    this._componentDestroyed$.next();
    this._componentDestroyed$.complete();
  }
}
