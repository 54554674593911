<router-outlet></router-outlet>
<div toastContainer></div>
<app-ar-instruction-modal></app-ar-instruction-modal>
<app-save-modal></app-save-modal>
<app-open-modal></app-open-modal>
<app-installation-service-modal></app-installation-service-modal>
<app-help-modal></app-help-modal>
<app-wood-type-modal></app-wood-type-modal>
<app-blocking-modal></app-blocking-modal>
<app-carousel-modal></app-carousel-modal>
<app-qr-code-modal></app-qr-code-modal>
<app-plp-modal></app-plp-modal>
<app-spinner-on-demand
></app-spinner-on-demand>
