import gql from "graphql-tag";
import {ASSET_FIELDS, CATALOG_FIELDS, CONFIGURATION_FIELDS, MODULE_BLUEPRINT_FIELDS, MODULE_VARIANT_FIELDS} from "@src/app/services/apollo/apollo-fragments";

export const QUERIES = {
  getAllAvailableModuleBlueprints: gql`
    ${MODULE_BLUEPRINT_FIELDS}
    query GetAllAvailableModuleBlueprints {
      allAvailableBlueprints {
        ...moduleBlueprintFragment
      }
    }
  `,
    getAllPublishedModuleBlueprints: gql`
        ${MODULE_BLUEPRINT_FIELDS}
        query GetAllPublishedModuleBlueprints {
            allPublishedBlueprints {
                ...moduleBlueprintFragment
            }
        }
    `,
  getUnavailableModuleBlueprints: gql`
    ${MODULE_VARIANT_FIELDS}
    query GetUnavailableModuleBlueprints {
      allUnavailableBlueprints {
        variantsPerWoodType {
            values {
                key
                value {
                    ...moduleVariantFragment
                }
            }
        }
      }
    }
  `,
  getAllModuleBlueprintsDraft: gql`
    ${MODULE_BLUEPRINT_FIELDS}
    query GetAllModuleBlueprintsDraft {
      allBlueprintsDraft {
        ...moduleBlueprintFragment
      }
    }
  `,
  getBlueprintById: gql`
    ${MODULE_BLUEPRINT_FIELDS}
    query GetBlueprintById($id: String!) {
      blueprint(id: $id) {
        ...moduleBlueprintFragment
      }
    }
  `,
  getVariantById: gql`
    ${MODULE_VARIANT_FIELDS}
    query GetVariantById($id: String!) {
      variant(id: $id) {
        woodType
        variant {
          ...moduleVariantFragment
        }
      }
    }
  `,
  getCatalog: gql`
    ${CATALOG_FIELDS}
    query GetCatalog($purchasePriceMandatory: Boolean!) {
      catalog(purchasePriceMandatory: $purchasePriceMandatory) {
        ...catalogFragment
      }
    }
  `,
  getCatalogDraft: gql`
    ${CATALOG_FIELDS}
    query GetCatalogDraft {
      catalogDraft {
        ...catalogFragment
      }
    }
  `,
  getConfigurationByCode: gql`
    ${CONFIGURATION_FIELDS}
    query GetConfigurationByCode($code: String!) {
      configuration(code: $code) {
        ...configurationFragment
      }
    }
  `,
  getPreConfigurationsByScope: gql`
    query GetPreConfigurationsByScope($scope: String!) {
      preConfigurations(scope: $scope) {
          id
          name
          code
          termsAcceptedOn
          scope
          image {
              id
              filename
              mimeType
              directory
              url
              storageFilename
          }
          price
      }
    }
  `,
  getCurrentScopeId: gql`
    query GetCurrentScopeId {
      currentScopeId
    }
  `,
  getCurrentRetailerOptions: gql`
    ${ASSET_FIELDS}
    query GetCurrentRetailerOptions {
      currentRetailerOptions {
          currencyOptions {
              currency
              showCurrency
              locale
              showThousandsSeparator
          }
          colorCodes {
              button
              buttonPrimaryHover
              buttonSecondaryHover
              gradient1
              gradient2
              cartButton
              cartButtonHover
              background
              selectedItemBackground
              bodyText
              headerText
              selectedItem
              icons
              activeStep
              inactiveStep
              dropShadow
              warn
              border
          }
          agreementLinks {
              href
              text
          }
          helpLink {
              href
              text
          }
          helpItems {
              order
              title
              thumbnailAsset {
                  ...assetFragment
              }
              videoAsset {
                  ...assetFragment
              }
          }
          woodTypesAvailable {
              woodType
              available
          }
          configurationIdentifier
          resultSceneSkyMaterialReference
          resultSceneFloorMaterialReference
          purchasePriceMandatory
          installationPrices {
              values {
                  key
                  value {
                      values {
                          key
                          value
                      }
                  }
              }
          }
          preconfigurations
          webhookOptions {
              flowType
              webhookUrls {
                  redirectUrl
              }
          }
          codeParameterName
          configuratorOptions {
              favicon {
                  ...assetFragment
              }
              banner {
                  ...assetFragment
              }
              font {
                  regularFontAsset {
                      ...assetFragment
                  }
                  boldFontAsset {
                      ...assetFragment
                  }
                  italicFontAsset {
                      ...assetFragment
                  }
                  mediumFontAsset {
                      ...assetFragment
                  }
              }
              configuratorIcons {
                  measurement {
                      ...assetFragment
                  }
                  woodType {
                      ...assetFragment
                  }
                  reset {
                      ...assetFragment
                  }
              }
              priceAppearance {
                  font {
                      ...assetFragment
                  }
                  color
              }
              bottomLogo
              saveButton
              hideSteps
              hideMenuSaveButton
              hideMenuOpenButton
              hideShareContent
              extraCardWidth,
              discountedPriceAppearance {
                  strikeThrough
                  textSize
                  color
                  
              }
          }
          hornbachOptions {
              integrationServiceUrl
              articleId
              companyCode
              locale
          }
      }
    }
  `,
  getTranslations: gql`
    query GetTranslations {
      translations {
        key
        value
      }
    }
  `,
  getMaterialsAndTextures: gql`
    ${ASSET_FIELDS}
    query GetMaterialsAndTextures {
      materials {
        id
        name
        color
        roughness
        metalness
        map
        lightMap
        lightMapIntensity
        aoMap
        aoMapIntensity
        emissive
        emissiveIntensity
        emissiveMap
        bumpMap
        bumpScale
        normalMap
        normalMapType
        normalScale {
          x
          y
        }
        displacementMap
        displacementScale
        displacementBias
        roughnessMap
        metalnessMap
        alphaMap
        envMap
        envMapIntensity
        wireframe
        wireframeLinewidth
        fog
        flatShading
        alphaTest
        alphaToCoverage
        blendDst
        blendDstAlpha
        blendEquation
        blendEquationAlpha
        blending
        blendSrc
        blendSrcAlpha
        clipIntersection
        clippingPlanes {
          normal {
            x
            y
            z
          }
          constant
        }
        clipShadows
        colorWrite
        depthFunc
        depthTest
        depthWrite
        opacity
        polygonOffset
        polygonOffsetFactor
        polygonOffsetUnits
        precision
        premultipliedAlpha
        dithering
        side
        shadowSide
        toneMapped
        transparent
        vertexColors
        visible
        format
        stencilWrite
        stencilFunc
        stencilRef
        stencilWriteMask
        stencilFuncMask
        stencilFail
        stencilZFail
        stencilZPass
      }
      textures {
        id
        name
        asset {
          ...assetFragment
        }
      }
    }
  `,
  getMarkerPlacement: gql`
    query GetMarkerPlacement($code: String!) {
      configuration(code: $code) {
        markerPlacement {
          mainMarkers {
            values {
              key
              value
            }
          }
          visualXAttachmentPointMarkers {
            values {
              key
              value
            }
          }
        }
      }
    }
  `
};

export const MUTATIONS = {
  uploadConfiguration: gql`
    mutation UploadConfiguration (
      $configurationPlacement: ConfigurationPlacementInput!, $history: [UndoConfigurationInput!]!,
      $renderState: RenderStateInput,
      $installationService: Boolean!
    ) {
      configuration {
        newConfigurationApp(
          configurationPlacement: $configurationPlacement,
          history: $history,
          renderState: $renderState,
          installationService: $installationService
        ) {
          code
        }
      }
    }
  `,
  updateConfiguration: gql`
    mutation UpdateConfiguration (
      $code: String!,
      $name: String!,
      $email: String!,
      $hasAcceptedTerms: Boolean!
    ) {
      configuration {
        updateConfigurationApp(code: $code, name: $name, email: $email, hasAcceptedTerms: $hasAcceptedTerms)
      }
    }
  `,
  placeOrder: gql`
    mutation PlaceOrder (
      $configurationPlacement: ConfigurationPlacementInput!,
      $price: Float!,
      $placementsInfo: IdModulePlacementListSpacialPositionInputInputMap!,
      $installationServiceEnabled: Boolean!
    ) {
      order {
        newConfigurationOrder(
          configurationPlacement: $configurationPlacement,
          price: $price,
          placementsInfo:$placementsInfo,
          installationServiceEnabled: $installationServiceEnabled
        ) {
          configurationOrder {
            price
            installationCost
            code
          }
          webhookPayload
        }
      }
    }
  `
};
