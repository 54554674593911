import {Injectable} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {
  BoundingBox,
  BoundingBoxSize,
  computeAllBBoxPositions,
  ConfigurationPlacement,
  DebugCall,
  emptyExecutableRuleFile,
  FatModuleVariant,
  id,
  JungleGymRuleExecution,
  RuleState
} from "@ess/jg-rule-executor";
import {InitDataService} from "@src/app/services/data/init-data.service";
import {Subject} from "rxjs";
import {cloneDeep} from "lodash-es";
import {BoxGeometry, Group, Mesh, MeshBasicMaterial, Vector3} from "three";
import {DEBUG} from "@src/app/constants";
import {ColorType} from "@src/app/generated/globalTypes";

// debug options for placement service
type DebugMode = {
  debug: boolean;
  bbox?: {
    moduleId: string
    pos: { x: number, y: number, z: number }
    size: { width: number, height: number, length: number }
  }
};

const defaultMode: DebugMode = {
  debug: false
};

const portalMode: DebugMode = {
  debug: true
};

@Injectable({
  providedIn: 'root'
})
export class DebugService {
  public latestDebugData: DebugCall[];
  public showDebug;
  public debugActivated: Subject<boolean> = new Subject<boolean>();

  constructor(
    private _dataService: InitDataService,
    private _translateService: TranslateService
  ) {
  }

  /**
   * Enable debug options through window object.
   * In the browser, assign the placementServiceDebug value through the console.
   */
  get debug(): DebugMode {
    return (window as any).showDebugMode ?? (this._dataService.authJwtToken ? portalMode : defaultMode);
  }

  activateDebug(): void {
    this.showDebug = !this.showDebug;
    this.debugActivated.next(this.showDebug);
    this._translateService.use('debug');
  }

  public logLatestDebugData(): void {
    console.log(
      "._____________________________________________________________________________.\n" +
      "|                                                                             |\n" +
      "|                                                                             |\n" +
      "|       _____   ______  ____   _    _   _____     _       ____    _____       |\n" +
      "|      |  __ \\ |  ____||  _ \\ | |  | | / ____|   | |     / __ \\  / ____|      |\n" +
      "|      | |  | || |__   | |_) || |  | || |  __    | |    | |  | || |  __       |\n" +
      "|      | |  | ||  __|  |  _ < | |  | || | |_ |   | |    | |  | || | |_ |      |\n" +
      "|      | |__| || |____ | |_) || |__| || |__| |   | |____| |__| || |__| |      |\n" +
      "|      |_____/ |______||____/  \\____/  \\_____|   |______|\\____/  \\_____|      |\n" +
      "|                                                                             |\n" +
      "|                                                                             |\n" +
      "|_____________________________________________________________________________|"
    );
    console.log("Entire debug data object: ", this.latestDebugData);
    console.log("Steps:");
    if (this.latestDebugData.length <= 1) {
      this._printDebugData(this.latestDebugData);
    } else {
      // Print the first entry; the main call
      this._printDebugData([this.latestDebugData[0]]);

      // Print the other calls (usually non-connected calls from rules and stuff)
      // Don't print these for now
      // const slicedDebugData = this.latestDebugData.slice(1);
      // console.log(`Other calls, most likely from within rules (${slicedDebugData.length} total):\n{`);
      // this._printDebugData(slicedDebugData);
      // console.log(`}`);
    }
  }

  public _printDebugData(debugData: DebugCall[]): void {
    for (const call of debugData) {
      const resObj = {};
      const resText = call.result ? `${call.title}, result: ${call.result.text ?? ""}` : call.title;

      if (call.args) {
        resObj["arguments"] = call.args;
      }
      if (call.result?.result) {
        resObj["result"] = call.result.result;
      }
      if (resObj && Object.keys(resObj).length !== 0) {
        console.log(resText, resObj);
      } else {
        console.log(resText);
      }

      if (call.subcalls.length > 0) {
        console.log(`Sub calls (${call.subcalls.length} total):\n{`);
        this._printDebugData(call.subcalls);
        console.log(`}`);
      }
    }
  }

  /**
   * Draws boxes around bounding boxes. Note that this function is NOT unit tested, since it's a debug feature.
   */
  public renderBBoxes(placement: ConfigurationPlacement): Group {
    const bboxesGroup = new Group();
    const ruleState = new RuleState(
      this._overrideBbox(this._dataService.availableFatModuleVariants),
      this._overrideBbox(this._dataService.inConfigAndPublishedModuleVariants),
      placement,
      id(this._dataService.currentScope),
      new JungleGymRuleExecution(emptyExecutableRuleFile)
    );
    const bboxes = computeAllBBoxPositions(ruleState);

    bboxes.forEach((bbox) => {
      const geometry = new BoxGeometry(
        bbox.bbox.size.width,
        bbox.bbox.size.height,
        bbox.bbox.size.length
      );

      const mesh = new Mesh(geometry, this.generateMaterial(bbox.bbox.color.color));

      mesh.position.add(bbox.position);
      mesh.rotation.copy(bbox.rotation);

      // debug objects are not used in raytracing
      mesh.userData = {[DEBUG]: 1};

      bboxesGroup.add(mesh);
    });

    return bboxesGroup;
  }

  /**
   * Will override bbox if defined in debug settings
   */
  private _overrideBbox(allVariants: FatModuleVariant[]): FatModuleVariant[] {
    if (this.debug.bbox) {
      const clonedList = Array.from(allVariants).map((fv) => {
        if (fv.blueprint.id === id(this.debug.bbox.moduleId)) {
          const newFv = cloneDeep(fv);
          newFv.blueprint.boundingBoxes = fv.blueprint.boundingBoxes.map(bb =>  new BoundingBox(
            new Vector3(
              this.debug.bbox.pos.x,
              this.debug.bbox.pos.y,
              this.debug.bbox.pos.z
            ),
            new BoundingBoxSize(
              this.debug.bbox.size.width,
              this.debug.bbox.size.height,
              this.debug.bbox.size.length
            ),
            bb.color
          ));
          return newFv;
        }
        return fv;
      });
      return clonedList;
    }
    return allVariants;
  }

  public generateMaterial(color?: string): MeshBasicMaterial {
    switch(color) {
      case ColorType.Red: return new MeshBasicMaterial({
        color: 0xff0000,
        wireframe: true
      });
      case ColorType.Orange: return new MeshBasicMaterial({
        color: 0xffa500,
        wireframe: true
      });
      case ColorType.Yellow: return new MeshBasicMaterial({
        color: 0xffff00,
        wireframe: true
      });
      case ColorType.Purple: return new MeshBasicMaterial({
        color: 0x9900ff,
        wireframe: true
      });
      // default: red
      default: return new MeshBasicMaterial({
        color: 0xff0000,
        wireframe: true
      });
    }
  }
}
