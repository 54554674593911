<div class="overlay">
    @if (debugService.debug.debug) {
        <app-button
            class="clickable debug-button"
            [buttonType]="'primary'"
            [leftIcon]="debugIcon"
            [label]="'Log debug data for latest action'"
            (onClick)="debugService.logLatestDebugData()">
        </app-button>
    }
    @if (showLogo) {
        <div class="bottom-logo-container">
            <svg-icon [src]="jgLogoSrc" class="{{(inEditMode | async) ? 'logo' : 'logo white'}}"></svg-icon>
        </div>
    }
    <div class="bottom-items-container">
        @if (showGesture) {
            <img class="gesture-gif" src="assets/gifs/gesture.gif"/>
        }
        @if (currentViewIndex === 2 && loadingFinished) {
            <div class="bottom-buttons-container">
                <!--Measurements-->
                <app-icon-series-button
                    class="clickable"
                    [styleInput]="'square top'"
                    [customIcon]="!!measurementIcons"
                    [buttons]="measurementIcons ?? standardMeasurementIcons"
                    (onClick)="rulerClick()"
                    gaTag="overlay.measurement">
                </app-icon-series-button>
                <!--Woodtype modal-->
                @if (showWoodTypeButton) {
                    <app-icon-series-button
                        class="clickable"
                        [styleInput]="'square middle'"
                        [customIcon]="!!woodTypeIcons"
                        [buttons]="woodTypeIcons ?? standardWoodTypeIcons"
                        (onClick)="woodTypeClick()"
                        gaTag="overlay.woodType">
                    </app-icon-series-button>
                }
                <!--Revert-->
                <app-icon-series-button
                    class="clickable"
                    [styleInput]="'square bottom'"
                    [customIcon]="!!resetIcons"
                    [buttons]="resetIcons ?? standardResetIcons"
                    (onClick)="revertClick()"
                    [disabled]="revertDisabled"
                    gaTag="overlay.revert">
                </app-icon-series-button>
            </div>
        }
    </div>
</div>
