// this file is automatically generated by git.settings.ts script
// eslint-disable
export const versions = {
  version: '2.9.0-3175',
  revision: 'norevision',
  branch: 'nobranch'
};
export const backendUrl = 'https://api-devel.mycustomplayground.com';
export const portalBackendUrl = 'https://api-devel.mycustomplayground.com/portal/';
export const sentryUrl = 'https://1be2a1e4f4f24a4f91549f4bfde1f128@sentry.eaglescience.nl/37';
