import {BrowserModule} from '@angular/platform-browser';
import {ErrorHandler, NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {ClipboardModule} from "@angular/cdk/clipboard";
import { provideHttpClient, withInterceptorsFromDi, withJsonpSupport } from '@angular/common/http';
import {CommonModule, registerLocaleData} from "@angular/common";
import localeNL from '@angular/common/locales/nl';
import {Apollo} from "apollo-angular";
import {environment} from '@src/environments/environment';
import {AppRoutingModule} from './app-routing.module';
import {CoreModule} from "@src/app/core.module";
import {LibraryModule} from "@src/app/library/library.module";
import {MissingTranslationHandler, TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {ConfiguratorMissingTranslationHandler, loaderFactory} from "@src/app/factories/translation-loader";
import {AppComponent} from './app.component';
import {HomeComponent} from './components/home/home.component';
import {CatalogComponent} from './components/catalog/catalog.component';
import {CanvasComponent} from './components/canvas/canvas.component';
import {CatalogItemComponent} from "@src/app/components/catalog/catalog-item/catalog-item.component";
import {FooterComponent} from "@src/app/components/footer/footer.component";
import {HeaderComponent} from "@src/app/components/header/header.component";
import {DemoComponent} from "@src/app/components/demo/demo.component";
import {CanvasOverlayComponent} from "@src/app/components/canvas-overlay/canvas-overlay.component";
import {NotificationCardComponent} from "@src/app/components/notification-card/notification-card.component";
import {AngularSvgIconModule} from "angular-svg-icon";
import {ToastContainerDirective, ToastrModule} from 'ngx-toastr';
import {ResultViewComponent} from "@src/app/components/resultView/result-view.component";
import {WoodTypeSelectionComponent} from "@src/app/components/modal/wood-type-modal/wood-type-selection/wood-type-selection.component";
import {accessInterceptorProvider} from "@src/app/interceptors/access/access.interceptor";
import {scopeInterceptorProvider} from "@src/app/interceptors/scope/scope.interceptor";
import {MenuComponent} from "@src/app/components/menu/menu.component";
import {SpinnerComponent} from "@src/app/components/spinner/spinner.component";
import {ModalComponent} from './components/modal/modal.component';
import {SaveModalComponent} from './components/modal/save-modal/save-modal.component';
import {OpenModalComponent} from "@src/app/components/modal/open-modal/open-modal.component";
import {BlockingModalComponent} from "@src/app/components/modal/blocking-modal/blocking-modal.component";
import {InstallationServiceModalComponent} from "@src/app/components/modal/installation-service-modal/installation-service-modal.component";
import {LoggingService} from "@src/app/services/logging/logging.service";
import {ComposedErrorHandler} from "@src/app/services/error/composed.error.handler";
import {SentryErrorHandler} from "@src/app/services/error/sentry.error.handler";
import {LoggerModule, NgxLoggerLevel} from "ngx-logger";
import {ScreenshotComponent} from "@src/app/components/screenshot/screenshot.component";
import {WoodTypeModalComponent} from "@src/app/components/modal/wood-type-modal/wood-type-modal.component";
import {CarouselModalComponent} from "@src/app/components/modal/carousel-modal/carousel-modal.component";
import {CarouselModule} from "@coreui/angular";
import {HelpModalComponent} from "@src/app/components/modal/help-modal/help.modal.component";
import {ArInstructionModalComponent} from "@src/app/components/modal/ar-instruction-modal/ar-instruction-modal.component";
import {QRCodeModule} from "angularx-qrcode";
import {QrCodeModalComponent} from "@src/app/components/modal/qr-code-modal/qr-code-modal.component";
import {CookieService} from "ngx-cookie-service";
import {ItemIsSelected} from "@src/app/pipes/itemSelectedPipe";
import {MapContainsValuePipe} from "@src/app/pipes/mapContainsValuePipe";
import {PreconfigSelectionComponent} from "@src/app/components/preconfig-selection/preconfig-selection.component";
import {PreconfigCardComponent} from "@src/app/components/preconfig-selection/preconfig-card/preconfig-card.component";
import {PlpSideViewComponent} from "@src/app/components/plp-side-view/plp-side-view.component";
import {PlpModalComponent} from "@src/app/components/modal/plp-modal/plp-modal.component";
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {
  InstallationServiceSelectionComponent
} from "@src/app/components/modal/installation-service-modal/installation-service-selection/installation-service-selection.component";
import {SpinnerOnDemandComponent} from "@src/app/components/spinner-on-demand/spinner-on-demand.component";
import {GoogleAnalyticsEventDirective} from "@src/app/directives/google-analytics-event.directive";

registerLocaleData(localeNL, 'nl');

/**
 * Determine if Sentry needs to be added to imports as we are not able to disable it when it is included.
 * Note that this error handler produced only makes sure thrown exceptions etc are logged, LoggingService errors are logged via LoggingService :)
 */
const errorHandlerFactory = (logger: LoggingService) => {
  const logErrorHandlers: ErrorHandler[] = [];
  if (environment.logging.serverLoggingEnabled) {
    logErrorHandlers.push(new SentryErrorHandler({dsn: environment.logging.serverUrl}));
  }
  // Compose the error handlers
  return new ComposedErrorHandler(
    logger,
    ...logErrorHandlers
  );
};

@NgModule({ declarations: [
        AppComponent,
        HomeComponent,
        CatalogItemComponent,
        CatalogComponent,
        CanvasComponent,
        CanvasOverlayComponent,
        FooterComponent,
        HeaderComponent,
        NotificationCardComponent,
        DemoComponent,
        ScreenshotComponent,
        MenuComponent,
        ResultViewComponent,
        ModalComponent,
        PlpModalComponent,
        OpenModalComponent,
        BlockingModalComponent,
        InstallationServiceModalComponent,
        InstallationServiceSelectionComponent,
        HelpModalComponent,
        WoodTypeModalComponent,
        CarouselModalComponent,
        ArInstructionModalComponent,
        QrCodeModalComponent,
        SpinnerComponent,
        ItemIsSelected,
        MapContainsValuePipe,
        PreconfigSelectionComponent,
        PreconfigCardComponent,
        PlpSideViewComponent,
        SaveModalComponent,
        WoodTypeSelectionComponent,
        SpinnerOnDemandComponent,
        GoogleAnalyticsEventDirective
    ],
    bootstrap: [AppComponent], imports: [AppRoutingModule,
        BrowserModule,
        CarouselModule,
        ClipboardModule,
        CommonModule,
        LibraryModule,
        FormsModule,
        ReactiveFormsModule,
        QRCodeModule,
        LoggerModule.forRoot({
            // Used for console logs only. Note that the serverLogUrl etc. does not work due to cors issues, hence the sentry logging monitor.
            level: NgxLoggerLevel.TRACE,
            disableConsoleLogging: false // Keep console output
        }),
        BrowserAnimationsModule,
        ToastrModule.forRoot({
            toastComponent: NotificationCardComponent,
            positionClass: 'toast-top-left',
            toastClass: 'toast-notification',
            closeButton: true,
            timeOut: 5000,
            maxOpened: 10
        }),
        ToastContainerDirective,
        AngularSvgIconModule.forRoot(),
        CoreModule.forRoot({
            accessTokenHeader: environment.accessTokenHeader,
            cookieName: environment.sessionCookieName,
            backendUrl: environment.backendUrl,
            portalBackendUrl: environment.portalBackendUrl,
            gqlEntryPoint: environment.gqlEntryPoint,
            usePortalBackend: new URL(window.location.href).searchParams.get('usePortal') === 'true'
        }),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: loaderFactory
            },
            missingTranslationHandler: { provide: MissingTranslationHandler, useClass: ConfiguratorMissingTranslationHandler },
            useDefaultLang: false,
            defaultLanguage: 'nl'
        })], providers: [
        // Add interceptor to add scope. Also adds them in GraphQL requests
        accessInterceptorProvider,
        scopeInterceptorProvider,
        Apollo,
        // LoggingService is used to log things defined by us, the ErrorHandler handles thrown exceptions (not by us)
        LoggingService,
        { provide: ErrorHandler, useFactory: errorHandlerFactory, deps: [LoggingService] },
        CookieService,
        provideHttpClient(withInterceptorsFromDi(), withJsonpSupport())
    ] })
export class AppModule {
}
